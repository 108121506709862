













































import { Component, Watch, Vue } from "vue-property-decorator";
import gEmpty from "@/components/g-empty.vue";
import gRoomList from "@/components/g-room-list.vue";
import EventBus from "@/assets/js/EventBus";
import { State } from "vuex-class";
import { guideServer } from "@/assets/js/Request";

@Component({
  components: {
    gEmpty,
    gRoomList,
  },
})
export default class SGSearch extends Vue {
  private searchHistory: Array<string> = [];
  private searchResult = [];
  private searchKey = "";
  private loading = true;
  private page = 1;
  private limit = 12;
  private getMoreTag = true;
  private noMoreTag = false;
  private form = {
    cid: 0,
  };

  @State("socketId") uid: any;

  @Watch("searchKey")
  onSearchKeyChange() {
    if (this.searchKey) {
      this.page = 1;
      this.noMoreTag = false;
      this.getMoreTag = true;
    }
  }

  toGuideIndex(): void {
    EventBus.$emit("toGuideIndex");
    this.$socket?.dispatchEvent("toGuideIndex", "");
  }

  // 添加历史记录并搜索
  newSearch(isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("searchProduct", this.searchKey);
    }

    if (this.searchKey) {
      if (!this.searchHistory.includes(this.searchKey)) {
        this.searchHistory.unshift(this.searchKey);
      }
    } else {
      this.$showToast("请输入搜索内容");
      return;
    }
    this.search();
  }

  // 直接搜索
  search(key = ""): void {
    if (key) {
      this.searchKey = key;
      this.$socket?.dispatchEvent("searchProduct", this.searchKey);
    }
    this.$get(guideServer + "/shopping_guide/get_products", {
      uid: this.uid || 0,
      cid: this.form.cid,
      search: this.searchKey,
      page: this.page,
      limit: this.limit,
    }).then((res: any) => {
      let data = res.data.data;

      if (this.page > 1) {
        this.searchResult = this.searchResult.concat(data.data);
      } else {
        this.searchResult = data.data;
      }

      this.loading = false;
      this.getMoreTag = false;
      this.noMoreTag = data.total <= this.page * this.limit;
    });
  }

  handleMessage(): void {
    EventBus.$on("searchProduct", (data: string) => {
      this.searchKey = data;
      this.newSearch(false);
    });
  }

  getMore(): void {
    if (this.getMoreTag) {
      return;
    }
    if (this.noMoreTag) {
      this.$showToast("没有更多数据了");
      return;
    }
    this.getMoreTag = true;
    this.page++;
    this.search();
  }

  mounted(): void {
    if (this.$socket) this.handleMessage();
  }
}
